import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import * as moment from 'moment'
import 'moment/locale/sv'
import styled from 'styled-components'

const Article = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 20px 0px 0px 0px;

  a {
    text-decoration: none;
    color: black;
  }
  h3 {
    font-family: 'Roboto slab', sans-serif;
    font-size: 1.3rem;
    margin-top: 10px;
    line-height: 1.8rem;
  }
  &:hover h3 {
    text-decoration: underline;
  }

  .pill {
    background: #d8d8d8;
    padding: 5px 15px;
    border-radius: 99px;
    margin-left: 15px;
  }
`

const News = () => {
  return (
    <StaticQuery
      query={graphql`
        query AllNewsItems {
          allAirtable(
            filter: { table: { eq: "Nyheter" } }
            limit: 4
            sort: { fields: [data___Datum], order: DESC }
          ) {
            edges {
              node {
                data {
                  Rubrik
                  Path
                  Datum
                  Kategori
                }
              }
            }
          }
        }
      `}
      render={data => {
        const news = data.allAirtable.edges
        return (
          <React.Fragment>
            {news.map(({ node: article }) => {
              return (
                <Article>
                  <Link to={article.data.Path}>
                    <span>
                      {moment(article.data.Datum)
                        .locale('sv')
                        .format('D MMMM, YYYY')}{' '}
                      <span className="pill"> {article.data.Kategori}</span>
                    </span>
                    <h3>{article.data.Rubrik}.</h3>
                  </Link>
                </Article>
              )
            })}
          </React.Fragment>
        )
      }}
    />
  )
}

export default News
